<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row class="match-height">
        <b-col lg="12">
          <b-overlay
            :show="ShowRound"
            variant="transparent"
            opacity="0.99"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <div class="text-center">
                <b-icon-controller
                  font-scale="3"
                  animation="cylon"
                />
                <p id="cancel-label">
                  กรุณารอสักครู่...
                </p>
              </div>
            </template>
            <b-card>
              <b-row>
                <b-col lg="6">
                  <h3>ลดหลั่น</h3>
                </b-col>
              </b-row>
              <b-form-group label-for="customDelimiter">
                <div class="d-flex justify-content-center align-items-center">
                  <!-- {{ LottoList }} -->
                  <v-select
                    v-model="LottoTypeHead"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="ListOp"
                    :reduce="(ele) => ele.id"
                    class="col-11"
                    @input=" GetAutoRate()"
                  />

                  <b-form-checkbox
                    v-if="LottoTypeHead"
                    v-model="RateLimit.status"
                    checked="true"
                    name="check-button"
                    switch
                    inline
                  >
                    เปิด
                  </b-form-checkbox>
                </div>
              </b-form-group>

              <div v-if="LottoTypeHead">

                <b-tabs
                  justified
                  pills
                  class="p-1"
                >
                  <b-tab
                    title="3 ตัวบน"
                  >
                    <b-row align-h="between mt-5">
                      <b-col
                        cols="5"
                        align-self="start"
                      >
                        <b-form-group
                          label="รับสูงสุด 3 ตัวบน"
                          label-for="input-default"
                        >
                          <b-form-input
                            id="input-default"
                            v-model="RateLimit.MaxAmountU3"
                            placeholder="รับสูงสุด 3 ตัวบน"
                            disabled
                            type="number"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="5"
                        align-self="end"
                      >
                        <b-form-group
                          label="ขั้น"
                          label-for="input-default"
                        >
                          <v-select
                            v-model="RateLimit.StepU3"
                            label="name"
                            :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                            :disabled="!RateLimit.MaxAmountU3"
                            @input="calculateLeft"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-for="item in RateLimit.StepU3"
                        :key="item"
                        cols="12"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <b-col
                          cols="5"
                          align-items="start"
                          class="text-center mb-1"
                        >
                          ขั้นที่ {{ item }}
                        </b-col>
                        <div>
                          <feather-icon
                            icon="ChevronsRightIcon"
                            size="16"
                            class="mb-1"
                          />
                        </div>
                        <b-col
                          cols="5"
                          align-items="center"
                        >
                          <b-form-input
                            v-model="RateLimit.StepRateU3[item-1]"
                            :placeholder="'เรทที่ ' + item"
                            :disabled="item === 1"
                            type="number"
                            class="mb-1"
                          />
                        </b-col>
                      </b-col>
                    </b-row>
                  </b-tab>

                  <b-tab
                    title="3 ตัวโต๊ด"
                  >

                    <b-row align-h="between mt-5">
                      <b-col
                        cols="5"
                        align-self="start"
                      >
                        <b-form-group
                          label="รับสูงสุด 3 ตัวโต๊ด"
                          label-for="input-default"
                        >
                          <b-form-input
                            id="input-default"
                            v-model="RateLimit.MaxAmountT3"
                            placeholder="รับสูงสุด 3 ตัวโต๊ด"
                            disabled
                            type="number"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="5"
                        align-self="end"
                      >
                        <b-form-group
                          label="ขั้น"
                          label-for="input-default"
                        >
                          <v-select
                            v-model="RateLimit.StepT3"
                            label="name"
                            :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                            :disabled="!RateLimit.MaxAmountT3"
                            @input="calculateLeft"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-for="item in RateLimit.StepT3"
                        :key="item"
                        cols="12"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <b-col
                          cols="5"
                          align-items="start"
                          class="text-center mb-1"
                        >
                          ขั้นที่ {{ item }}
                        </b-col>
                        <div>
                          <feather-icon
                            icon="ChevronsRightIcon"
                            size="16"
                            class="mb-1"
                          />
                        </div>
                        <b-col
                          cols="5"
                          align-items="center"
                        >
                          <b-form-input
                            v-model="RateLimit.StepRateT3[item - 1]"
                            :placeholder="'เรทที่ ' + item"
                            :disabled="item === 1"
                            type="number"
                            class="mb-1"
                          />
                        </b-col>
                      </b-col>
                    </b-row>
                  </b-tab>

                  <b-tab
                    title="2 ตัวบน"
                  >

                    <b-row align-h="between mt-5">
                      <b-col
                        cols="5"
                        align-self="start"
                      >
                        <b-form-group
                          label="รับสูงสุด 2 ตัวบน"
                          label-for="input-default"
                        >
                          <b-form-input
                            id="input-default"
                            v-model="RateLimit.MaxAmountU2"
                            placeholder="รับสูงสุด 2 ตัวบน"
                            disabled
                            type="number"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="5"
                        align-self="end"
                      >
                        <b-form-group
                          label="ขั้น"
                          label-for="input-default"
                        >
                          <v-select
                            v-model="RateLimit.StepU2"
                            label="name"
                            :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                            :disabled="!RateLimit.MaxAmountU2"
                            @input="calculateLeft"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-for="item in RateLimit.StepU2"
                        :key="item"
                        cols="12"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <b-col
                          cols="5"
                          align-items="start"
                          class="text-center mb-1"
                        >
                          ขั้นที่ {{ item }}
                        </b-col>
                        <div>
                          <feather-icon
                            icon="ChevronsRightIcon"
                            size="16"
                            class="mb-1"
                          />
                        </div>
                        <b-col
                          cols="5"
                          align-items="center"
                        >
                          <b-form-input
                            v-model="RateLimit.StepRateU2[item - 1]"
                            :placeholder="'เรทที่ ' + item"
                            :disabled="item === 1"
                            type="number"
                            class="mb-1"
                          />
                        </b-col>
                      </b-col>
                    </b-row>
                  </b-tab>

                  <b-tab
                    title="2 ตัวล่าง"
                  >

                    <b-row align-h="between mt-5">
                      <b-col
                        cols="5"
                        align-self="start"
                      >
                        <b-form-group
                          label="รับสูงสุด 2 ตัวล่าง"
                          label-for="input-default"
                        >
                          <b-form-input
                            id="input-default"
                            v-model="RateLimit.MaxAmountD2"
                            placeholder="รับสูงสุด 2 ตัวล่าง"
                            disabled
                            type="number"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="5"
                        align-self="end"
                      >
                        <b-form-group
                          label="ขั้น"
                          label-for="input-default"
                        >
                          <v-select
                            v-model="RateLimit.StepD2"
                            label="name"
                            :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                            :disabled="!RateLimit.MaxAmountD2"
                            @input="calculateLeft"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-for="item in RateLimit.StepD2"
                        :key="item"
                        cols="12"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <b-col
                          cols="5"
                          align-items="start"
                          class="text-center mb-1"
                        >
                          ขั้นที่ {{ item }}
                        </b-col>
                        <div>
                          <feather-icon
                            icon="ChevronsRightIcon"
                            size="16"
                            class="mb-1"
                          />
                        </div>
                        <b-col
                          cols="5"
                          align-items="center"
                        >
                          <b-form-input
                            v-model="RateLimit.StepRateD2[item - 1]"
                            :placeholder="'เรทที่ ' + item"
                            :disabled="item === 1"
                            type="number"
                            class="mb-1"
                          />
                        </b-col>
                      </b-col>
                    </b-row>
                  </b-tab>

                  <b-tab
                    title="วิ่งบน"
                  >

                    <b-row align-h="between mt-5">
                      <b-col
                        cols="5"
                        align-self="start"
                      >
                        <b-form-group
                          label="รับสูงสุด วิ่งบน"
                          label-for="input-default"
                        >
                          <b-form-input
                            id="input-default"
                            v-model="RateLimit.MaxAmountU1"
                            placeholder="รับสูงสุด วิ่งบน"
                            disabled
                            type="number"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="5"
                        align-self="end"
                      >
                        <b-form-group
                          label="ขั้น"
                          label-for="input-default"
                        >
                          <v-select
                            v-model="RateLimit.StepU1"
                            label="name"
                            :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                            :disabled="!RateLimit.MaxAmountU1"
                            @input="calculateLeft"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-for="item in RateLimit.StepU1"
                        :key="item"
                        cols="12"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <b-col
                          cols="5"
                          align-items="start"
                          class="text-center mb-1"
                        >
                          ขั้นที่ {{ item }}
                        </b-col>
                        <div>
                          <feather-icon
                            icon="ChevronsRightIcon"
                            size="16"
                            class="mb-1"
                          />
                        </div>
                        <b-col
                          cols="5"
                          align-items="center"
                        >
                          <b-form-input
                            v-model="RateLimit.StepRateU1[item - 1]"
                            :placeholder="'เรทที่ ' + item"
                            :disabled="item === 1"
                            type="number"
                            class="mb-1"
                          />
                        </b-col>
                      </b-col>
                    </b-row>
                  </b-tab>

                  <b-tab
                    title="วิ่งล่าง"
                  >

                    <b-row align-h="between mt-5">
                      <b-col
                        cols="5"
                        align-self="start"
                      >
                        <b-form-group
                          label="รับสูงสุด วิ่งล่าง"
                          label-for="input-default"
                        >
                          <b-form-input
                            id="input-default"
                            v-model="RateLimit.MaxAmountD1"
                            placeholder="รับสูงสุด วิ่งล่าง"
                            disabled
                            type="number"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="5"
                        align-self="end"
                      >
                        <b-form-group
                          label="ขั้น"
                          label-for="input-default"
                        >
                          <v-select
                            v-model="RateLimit.StepD1"
                            label="name"
                            :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                            :disabled="!RateLimit.MaxAmountD1"
                            @input="calculateLeft"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-for="item in RateLimit.StepD1"
                        :key="item"
                        cols="12"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <b-col
                          cols="5"
                          align-items="start"
                          class="text-center mb-1"
                        >
                          ขั้นที่ {{ item }}
                        </b-col>
                        <div>
                          <feather-icon
                            icon="ChevronsRightIcon"
                            size="16"
                            class="mb-1"
                          />
                        </div>
                        <b-col
                          cols="5"
                          align-items="center"
                        >
                          <b-form-input
                            v-model="RateLimit.StepRateD1[item - 1]"
                            :placeholder="'เรทที่ ' + item"
                            :disabled="item === 1"
                            type="number"
                            class="mb-1"
                          />
                        </b-col>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>

                <hr>
                <div class="d-flex justify-content-end align-items-center">
                  <b-button
                    variant="gradient-danger mr-1"
                    style="width: 150px"
                    @click="ClearData"
                  >
                    ยกเลิก
                  </b-button>
                  <b-button
                    variant="gradient-primary"
                    style="width: 150px"
                    @click="Submit"
                  >
                    บันทึก
                  </b-button>
                </div>
              </div>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>
<script>
import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  // BProgress,
  // BProgressBar,
  BRow,
  BTabs,
  BTab,
} from 'bootstrap-vue'
// import moment from 'moment-timezone'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    // BInputGroupPrepend,
    // BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    // BPagination,
    // BTable,
    // ValidationProvider,
    ValidationObserver,
    // BProgress,
    // BProgressBar,
    BFormCheckbox,
    BTabs,
    BTab,
  },
  data() {
    return {
      //   between,
      //   integer,
      //   length,
      //   required,
      totalSteps: 4,
      currentStep: 1,
      ShowRound: false,
      show: true,
      LottoTypeHead: null,
      LottoList: JSON.parse(localStorage.getItem('LottoList')),
      ListSelect: [],
      LottoSubList: null,
      step: [],
      values: null,
      maxrate3up: null,
      maxrate3Toad: null,
      maxrate2up: null,
      maxrate2down: null,
      maxrate_runUp: null,
      maxrate_runDown: null,
      rateStep: [],
      ProgressTest: 0,
      percentWidth: 0,
      RateLimit: {
        status: false,
        MaxAmountU3: 0,
        MaxAmountT3: 0,
        MaxAmountU2: 0,
        MaxAmountD2: 0,
        MaxAmountU1: 0,
        MaxAmountD1: 0,
        StepU3: 1,
        StepT3: 1,
        StepU2: 1,
        StepD2: 1,
        StepU1: 1,
        StepD1: 1,
        StepRateU3: [],
        StepRateT3: [],
        StepRateU2: [],
        StepRateD2: [],
        StepRateU1: [],
        StepRateD1: [],
      },
      ListOp: [],
    }
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.GetSublist()
  },
  methods: {
    cal(line) {
      const cols = this.percentWidth * line
      return cols
    },
    calculateLeft() {
      this.percentWidth = 100 / this.step
    },
    async GetSublist() {
      try {
        const { data: Res } = await this.$http.get('Auto/LottoList')
        if (Res) {
          this.LottoList = Res
          this.ListOp = this.LottoList.map(item => ({
            id: item.id,
            name: item.name,
          }))
          // console.log(LogMap)
        }
        this.ShowRound = false
      } catch (e) {
        console.log(e)
      }
    },
    async GetAutoRate() {
      this.ClearData()
      if (this.LottoTypeHead) {
        this.ShowRound = true
        try {
          // this.ClearValue()
          const params = {
            LottoTypeHead: this.LottoTypeHead,
          }
          const { data: Res } = await this.$http.get('auto/ratelimit/show', { params })
          if (Res && Res.success) {
            const Rate = Res.mes
            this.RateLimit = Rate
          } else {
            this.RateLimit = {
              status: false,
              MaxAmountU3: 0,
              MaxAmountT3: 0,
              MaxAmountU2: 0,
              MaxAmountD2: 0,
              MaxAmountU1: 0,
              MaxAmountD1: 0,
              StepU3: 1,
              StepT3: 1,
              StepU2: 1,
              StepD2: 1,
              StepU1: 1,
              StepD1: 1,
              StepRateU3: [],
              StepRateT3: [],
              StepRateU2: [],
              StepRateD2: [],
              StepRateU1: [],
              StepRateD1: [],
            }
            this.GetRate()
            this.ShowRound = false
          }
        } catch (e) {
          console.log(e)
          this.ShowRound = false
        }
      }
    },
    async GetRate() {
      if (this.LottoTypeHead) {
        this.ShowRound = true
        try {
          // this.ClearValue()
          const params = {
            LottoTypeHead: this.LottoTypeHead,
          }
          const { data: Res } = await this.$http.get('rate/ShowByType', { params })
          if (Res && Res.success) {
            const Rate = Res.mes
            this.CalRate(Rate)
            this.ShowRound = false
          } else {
            const MainRate = this.LottoList.find(item => item.id === this.LottoTypeHead)
            this.CalRateAuto(MainRate)
            this.ShowRound = false
          }
        } catch (e) {
          console.log(e)
          this.ShowRound = false
        }
      }
    },
    CalRate(Rate) {
      this.RateLimit.StepRateU3.push(Rate.U3)
      this.RateLimit.StepRateT3.push(Rate.T3)
      this.RateLimit.StepRateU2.push(Rate.U2)
      this.RateLimit.StepRateD2.push(Rate.D2)
      this.RateLimit.StepRateU1.push(Rate.U1)
      this.RateLimit.StepRateD1.push(Rate.D1)

      this.RateLimit.MaxAmountU3 = Rate.BetLimitU3
      this.RateLimit.MaxAmountT3 = Rate.BetLimitT3
      this.RateLimit.MaxAmountU2 = Rate.BetLimitU2
      this.RateLimit.MaxAmountD2 = Rate.BetLimitD2
      this.RateLimit.MaxAmountU1 = Rate.BetLimitU1
      this.RateLimit.MaxAmountD1 = Rate.BetLimitD1
    },
    CalRateAuto(Rate) {
      this.RateLimit.StepRateU3.push(Rate.rate_u3)
      this.RateLimit.StepRateT3.push(Rate.rate_t3)
      this.RateLimit.StepRateU2.push(Rate.rate_u2)
      this.RateLimit.StepRateD2.push(Rate.rate_d2)
      this.RateLimit.StepRateU1.push(Rate.rate_u1)
      this.RateLimit.StepRateD1.push(Rate.rate_d1)

      this.RateLimit.MaxAmountU3 = Rate.limit_u3
      this.RateLimit.MaxAmountT3 = Rate.limit_t3
      this.RateLimit.MaxAmountU2 = Rate.limit_u2
      this.RateLimit.MaxAmountD2 = Rate.limit_d2
      this.RateLimit.MaxAmountU1 = Rate.limit_u1
      this.RateLimit.MaxAmountD1 = Rate.limit_d1
    },
    ClearData() {
      this.RateLimit.StepRateU3 = []
      this.RateLimit.StepRateT3 = []
      this.RateLimit.StepRateU2 = []
      this.RateLimit.StepRateD2 = []
      this.RateLimit.StepRateU1 = []
      this.RateLimit.StepRateD1 = []
      this.RateLimit.MaxAmountU3 = 0
      this.RateLimit.MaxAmountT3 = 0
      this.RateLimit.MaxAmountU2 = 0
      this.RateLimit.MaxAmountD2 = 0
      this.RateLimit.MaxAmountU1 = 0
      this.RateLimit.MaxAmountD1 = 0
      this.RateLimit.StepU3 = 1
      this.RateLimit.StepT3 = 1
      this.RateLimit.StepU2 = 1
      this.RateLimit.StepD2 = 1
      this.RateLimit.StepU1 = 1
      this.RateLimit.StepD1 = 1
      this.RateLimit.status = false
    },
    async Submit() {
      try {
        this.ShowRound = true
        this.RateLimit.LottoTypeHead = this.LottoTypeHead
        const convertStepRateToNumber = stepRate => stepRate.map(value => (typeof value === 'string' ? parseFloat(value) : value))
        // eslint-disable-next-line no-restricted-syntax
        for (const key in this.RateLimit) {
          if (key.startsWith('StepRate')) {
            this.RateLimit[key] = convertStepRateToNumber(this.RateLimit[key])
          }
        }
        // eslint-disable-next-line no-underscore-dangle
        if (this.RateLimit._id) {
          await this.$http.put('auto/ratelimit/update', this.RateLimit)
        } else {
          const { data: res } = await this.$http.post('auto/ratelimit/store', this.RateLimit)
          this.RateLimit = res.mes
        }
        this.ShowRound = false
        await this.GetSublist()
        this.Success()
      } catch (e) {
        console.log(e)
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>
<style scoped>
.bar-container{
    width: 100%;
    height: 20px;
    position: relative;
    background-color: rgb(196, 196, 196);
    box-shadow: rgb(0, 0, 0,0.4) 5px 5px 5px;
    outline-style: solid;
    outline-width: 3px;
    outline-color: rgb(0, 0, 0,0.4);
    border-radius: 50px;
}
.bar {
  height: 20px;
  /* max-width: 100%; */
  background-color: rgb(62, 236, 129);
  /* animation: color 20s infinite linear; */
  position: relative;
  border-radius: 50px;
}

.line {
  height: 25px;
  width: 2px;
  background-color: rgb(0, 0, 0,0.2);
  position: absolute;
  top: 0;
}

.line span{
  width: 100px;
  top: 30px;
  font-size: 12px;
  font-weight: 700;
  left: 50%;
  transform: translateX(-50%);
  position: absolute; /* เพิ่มบรรทัดนี้ */
}

@media only screen and (max-width: 600px) {
  .line span{
    display: none;
  }
}

</style>
